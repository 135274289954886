import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b93b71a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "StudyElement--Info" }
const _hoisted_2 = { class: "StudyElement--Dates" }
const _hoisted_3 = {
  key: 0,
  class: "StudyElement--Date"
}
const _hoisted_4 = {
  key: 1,
  class: "StudyElement--Date"
}
const _hoisted_5 = { class: "StudyElement--Date" }
const _hoisted_6 = { class: "StudyElement--Date" }
const _hoisted_7 = {
  key: 2,
  class: "StudyElement--Date"
}
const _hoisted_8 = {
  key: 3,
  class: "StudyElement--Date"
}
const _hoisted_9 = {
  key: 4,
  class: "StudyElement--Date"
}
const _hoisted_10 = {
  key: 5,
  class: "StudyElement--Date"
}
const _hoisted_11 = { class: "StudyElement--Actions" }
const _hoisted_12 = { class: "StudyElement--TestTracking" }
const _hoisted_13 = { class: "StudyElement--TestTrackingHeader" }
const _hoisted_14 = { class: "StudyElement--TestTrackingContent" }
const _hoisted_15 = {
  key: 0,
  class: "StudyElement--Media"
}
const _hoisted_16 = { class: "StudyElement--OnlyAction" }
const _hoisted_17 = {
  key: 1,
  style: {"position":"absolute","bottom":"0","z-index":"-1"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StudyElementActionBox = _resolveComponent("StudyElementActionBox")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_VideoViewer = _resolveComponent("VideoViewer")!
  const _component_QuestionsExportPDF = _resolveComponent("QuestionsExportPDF")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["StudyElement", {'StudyElement--Single':_ctx.singleElement}])
  }, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.isTest())
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('DRAFT_DATE')), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$filters.formatDate(_ctx.element.draftDate,'DD/MM/YYYY')), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isCommunity())
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('CREATION_DATE')), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$filters.formatDate(_ctx.element.draftDate,'DD/MM/YYYY')), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('CURRENT_DATE')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$filters.formatDate(_ctx.element.inprogressDate,'DD/MM/YYYY')), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('FINISHED_DATE')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$filters.formatDate(_ctx.element.finishedDate,'DD/MM/YYYY')), 1)
        ]),
        (_ctx.isCommunity())
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('REVIEW_END_DATE')), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$filters.formatDate(_ctx.element.revisionEndDate,'DD/MM/YYYY')), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.element.showDurationinClientArea)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('DURATION')), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.element.durationSeconds), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isCommunity())
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('ACTIVITIES')), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.element.activitiesNum), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.element.showIRinClientArea)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('IR')), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.element.IR), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_11, [
        (_ctx.isTest())
          ? (_openBlock(), _createBlock(_component_StudyElementActionBox, {
              key: 0,
              text: _ctx.$t('suite_test'),
              icon: "icon-pdf-icon",
              hoverIcon: "cloud-download-alt",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadTestPDF()))
            }, null, 8, ["text"]))
          : _createCommentVNode("", true),
        (_ctx.isTest())
          ? (_openBlock(), _createBlock(_component_StudyElementActionBox, {
              key: 1,
              text: _ctx.$t('suite_preview'),
              icon: "icon-eye-icon",
              hoverIcon: "external-link-alt",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.checkPreviewRedirection()))
            }, null, 8, ["text"]))
          : _createCommentVNode("", true),
        (_ctx.isExcelDownloadEnabled())
          ? (_openBlock(), _createBlock(_component_StudyElementActionBox, {
              key: 2,
              text: _ctx.$t('suite_excel'),
              icon: "icon-excel-icon",
              hoverIcon: "cloud-download-alt",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.downloadExcel()))
            }, null, 8, ["text"]))
          : _createCommentVNode("", true),
        (_ctx.isPublic && _ctx.createdByClient)
          ? (_openBlock(), _createBlock(_component_StudyElementActionBox, {
              key: 3,
              text: _ctx.$t('suite_watux_step4_share_url'),
              icon: "icon-door-icon",
              hoverIcon: "copy",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.copyLink()))
            }, null, 8, ["text"]))
          : _createCommentVNode("", true),
        (_ctx.isTest() && _ctx.isWatMonitorEnabled() && !_ctx.isNeuro)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 4,
              to: {name:'watmonitor',params:{id:_ctx.element.elementId}}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_StudyElementActionBox, {
                  text: "WAT Monitor",
                  icon: "icon-watlogo-icon",
                  hoverIcon: "external-link-alt"
                })
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true),
        (_ctx.isTest() && _ctx.isWatMonitorEnabled() && _ctx.isNeuro)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 5,
              to: {name:'watmonitorneuro',params:{id:_ctx.contractId}}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_StudyElementActionBox, {
                  text: "Resultados Neuro",
                  icon: "icon-watlogo-icon",
                  hoverIcon: "external-link-alt"
                })
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_StudyElementActionBox, {
          text: _ctx.$t('suite_report'),
          icon: "icon-monitor-icon",
          hoverIcon: "cloud-download-alt",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.downloadReport())),
          disabled: !_ctx.isReportDownloadEnabled()
        }, null, 8, ["text", "disabled"]), [
          [_vShow, _ctx.element.showReport]
        ]),
        (_ctx.isCommunity())
          ? (_openBlock(), _createBlock(_component_StudyElementActionBox, {
              key: 6,
              text: _ctx.$t('suite_action_go_community'),
              icon: "icon-door-icon",
              hoverIcon: "external-link-alt",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.checkPreviewRedirection()))
            }, null, 8, ["text"]))
          : _createCommentVNode("", true)
      ])
    ], 512), [
      [_vShow, !_ctx.onlyTestPdf]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_12, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('FIELD_TRACKING')), 1),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('QUOTA_TITLE')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('TOTAL_AVAILABLE')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('FULL_COMPLETED')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('PERCENT_FIELD')), 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.element.quotaDTOs, (quota) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("p", null, _toDisplayString(quota.translatedText), 1),
          _createElementVNode("p", null, _toDisplayString(quota.availableSlots), 1),
          _createElementVNode("p", null, _toDisplayString(quota.completedSlots), 1),
          _createElementVNode("p", null, [
            _createVNode(_component_ProgressBar, {
              progress: quota.progrespctg,
              hideNumbers: true
            }, null, 8, ["progress"])
          ])
        ]))
      }), 256))
    ], 512), [
      [_vShow, _ctx.element.quotaDTOs && _ctx.element.showFollowUpinClientArea]
    ]),
    (_ctx.element.videoUrls && _ctx.element.videoUrls.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('VIDEOS_OF_VIDEO_CALL')), 1),
          _createVNode(_component_VideoViewer, {
            videos: _ctx.element.videoUrls
          }, null, 8, ["videos"])
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_16, [
      (_ctx.isTest())
        ? (_openBlock(), _createBlock(_component_StudyElementActionBox, {
            key: 0,
            text: _ctx.$t('suite_test'),
            icon: "icon-pdf-icon",
            hoverIcon: "cloud-download-alt",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.downloadTestPDF()))
          }, null, 8, ["text"]))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, _ctx.onlyTestPdf]
    ]),
    (_ctx.downloadingPDF)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createVNode(_component_QuestionsExportPDF, {
            ref: "QuestionsExportPDF",
            test: _ctx.test,
            navigationRules: _ctx.navigationRules,
            onPdfDownloaded: _cache[7] || (_cache[7] = ($event: any) => (_ctx.pdfDownloaded()))
          }, null, 8, ["test", "navigationRules"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}